import styled from "styled-components"
import {device} from "../../styles/devices"


const Modal = styled.div<any>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity linear 0.15s;
  z-index: 2000;
  width: 100%;


  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }

  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }

  .background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-close {
    display: flex;
    height: 0;
    flex-direction: row-reverse;
    width: 100%;
    background: red;
  }

  .box-dialog {
    overflow-y: scroll;
    z-index: 1050;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    padding: 40px;
    border-radius: 0;
    background-color: #fefefe;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.2);

    @media (${device.tablet}) {
      border-radius: 10px;
      width: 550px;
      height: auto;
    }
  }
`

export default Modal



export const Container = styled.div<any>`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  
  @media (${device.tablet}) {
     width: 550px;
    height: 661px;
    position: absolute;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 15px;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  margin: 0 0 40px 0;
  color: #060606;
`
export const Article = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
  color: #6f6f6f;
`
export const Input = styled.input`
  padding-left: 12px;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 140%;
  width: 100%;
  height: 48px;
  background: #ECECEC;
  border: none;
  border-radius: 4px;
`
export const TextAria = styled.textarea`
  resize: none;
  height: 150px;
  padding: 12px;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 140%;
  width: 100%;
  background: #ECECEC;
  border: none;
  border-radius: 4px;

  ::-webkit-scrollbar-thumb {
    background-color: #8e8e8e;
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: content-box;
  }
`

export const CloseButton = styled.button`
  position: relative;
  //position: absolute;
  display: flex;
  justify-content: end;
  z-index: 20000;
  top: -16px;
  right: -16px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`
export const Resize = styled.div`
  width: 200px;
  margin-top: 40px;
`
