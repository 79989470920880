import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonContact,
  SendButton,
  FlexBetweenLayout,
  FlexCenterLayout,
  Icon,
  SecondaryText,
  ContactFrameButton
} from "./style"

import {ICaseModel} from "../../models/format"

interface IButtonProps {
  children: string | ICaseModel
  type: "primary" | "secondary" | "contact" | "send" | "contactFrame"
  onClick: any
  clicked?: boolean
}

export default ({children, type, clicked=false, onClick}: IButtonProps) => {
  let Button
  switch (type) {
    case "primary":
      Button = <ButtonPrimary
        onClick={onClick}
        clicked={clicked}>
        <FlexBetweenLayout>
          <div>{children}</div>
          <Icon clicked={clicked}/>
        </FlexBetweenLayout>
      </ButtonPrimary>
      break
    case "secondary":
      const formatCase = children as ICaseModel
      Button = <ButtonSecondary
        onClick={onClick}
        clicked={clicked}>
        <FlexBetweenLayout>
          <div>{formatCase.name}</div>
          <SecondaryText clicked={clicked}>{formatCase.category}</SecondaryText>
        </FlexBetweenLayout>
      </ButtonSecondary>
      break
    case "contact":
      Button = <ButtonContact onClick={onClick}>
        <FlexCenterLayout>
          {children}
        </FlexCenterLayout>
      </ButtonContact>
      break
    case "contactFrame":
      Button = <ContactFrameButton onClick={onClick}>
        <FlexCenterLayout>
          {children}
        </FlexCenterLayout>
      </ContactFrameButton>
      break
    case "send":
      Button = <SendButton onClick={onClick}>
        <FlexCenterLayout>
          {children}
        </FlexCenterLayout>
      </SendButton>
      break
    default:
      throw Error
  }
  return <>
    { Button }
  </>
}