import styled from 'styled-components'
import {device} from "../../styles/devices"


export const Container = styled.div<any>`
  display: none;
  @media (${device.tablet}) { 
     display: ${({show}) => show? "block": "none"};
  }
  
  z-index: 1000;
  @keyframes show {
   from { 
     opacity: 0; 
   }
   to { 
     opacity: 100%; 
   }
  }
  animation: show 0.2s ease-in-out;

  width: 260px;
  padding: 12px;
  color: white;
  position: absolute;
  border-radius: 4px;
  top: 190px;
  left: 90px;
`

export const Info = styled.div<any>`
  display: ${({show}) => show? "block": "none"};
  background: #060606;
  width: 260px;
  padding: 12px;
  color: white;
  border-radius: 4px;

`

export const Title = styled.h3`
  margin: 0 0 8px 0;
`
export const Text = styled.p`
  margin: 0;
  color: #B3B3B3;
`

export const Triangle = styled.div`
  ::after {
    content: ''; 
    position: absolute; /* Абсолютное позиционирование */
    left: -8px;
    top: 25px;
    border: 10px solid transparent; /* Прозрачные границы */
    border-right: 10px solid #060606; /* Добавляем треугольник */
   }
`


