import {createGlobalStyle} from "styled-components"
import "../assets/font/stylesheet.css"

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "Mazzard M", sans-serif !important;
    
  }

  body {
    background: black;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
`



