import {Article, Center, Input, Resize, TextAria, Title} from "../Navigation/style"
import React, {BaseSyntheticEvent, useState} from "react"
import Button from "../Button"
import { sendContact } from "../../store/actions"
import {useTranslation} from "react-i18next"
import {useDispatch } from "react-redux"

interface INavigationProps {
  setModalState: (modalState: boolean) => void
}

export default ( { setModalState }: INavigationProps ) => {

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [colorName, setColorName] = useState("#ECECEC")
  const [colorEmail, setColorEmail] = useState("#ECECEC")
  const [colorMessage, setColorMessage] = useState("#ECECEC")

  const [message, setMessage] = useState("")
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")



  const validateInputs = () => {
    if (!name || name === " ") {
      setColorName("red")
    }
    if (!email || email === " " || !email.includes("@")) {
      setColorEmail("red")
    }
    if (!message || message === " ") {
      setColorMessage("red")
    }
  }

  return (
    <>
      <Title>{t('Contact us')}</Title>
      <div>
        <Article>{t('Name')}</Article>
        <Input
          colorBorder={colorName}
          value={name}
          type="text"
          onChange={(e: BaseSyntheticEvent) => setName(e.target.value)}
          placeholder={t('Enter name')}
          required/>
      </div>
      <div>
        <Article>{t('E-mail')}</Article>
        <Input
          colorBorder={colorEmail}
          value={email}
          type="email"
          onChange={(e: BaseSyntheticEvent) => setEmail(e.target.value)}
          placeholder={t('Enter e-mail')}
          required/>
      </div>
      <div>
        <Article>{t('Message')}</Article>
        <TextAria
          colorBorder={colorMessage}
          value={message}
          onChange={(e: BaseSyntheticEvent) => setMessage(e.target.value)}
          placeholder={t('Enter message')}
          required/>
      </div>
      <Center>
        <Resize size="300px">
          <Button type="send" onClick={()=>{
            if (name && email && message && email.includes("@")) {
              dispatch(sendContact({name, email, message}))
              setModalState(false)
            } else {
              validateInputs()
            }
          }}>{t('Send')}</Button>
        </Resize>
      </Center>
    </>
  )
}