import styled from 'styled-components'

export const BaseButtonStyle = styled.button`
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  height: 50px;
  border: none;
  width: 100%;
  outline: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
`

export const NavIconsButtonStyle = styled<any>(BaseButtonStyle)`
  z-index: 2000;
  background: ${({ selected }) => (selected ? 'linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(91.7deg, #F04A5E 0%, #F8AF5A 50.73%, #F97A69 99.39%)' : "#060606")};
  color: #ffffff;
  border-radius: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  padding: 0;
  transform: ${({rotate}) => rotate? "rotate(180deg)": "rotate(0)"};
  ${props => !props.selected && !props.offHover && `
      :hover {
        background: #242424;
      }
  `}
`
