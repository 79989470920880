import React, {useEffect, useState} from 'react'
import Navigation from '../../components/Navigation'
import CaseFrame from '../../components/CaseFrame'
import MobileHeader from '../../components/MobileHeader'
import {FlexContainer, ContentContainer, ButtonContainer} from './style'
import {useDispatch, useSelector} from "react-redux"
import {fetchFormats} from "../../store/actions"
import {IRootState} from "../../store/initialState"
import i18n from '../../locales'
import Modal from "../../components/Modal"
import ContactCard from "../../components/ContactCard"

export default ({lang}: any) => {
  const dispatch = useDispatch()
  const { formats, activeUrl } = useSelector((state: IRootState) => state)
  const [isModalOpen, setModalState] = useState(false)
  const toggleStateModal = () => {
    setModalState(!isModalOpen)
  }
  useEffect(() => {
    i18n.changeLanguage(lang)
    dispatch(fetchFormats(lang))
  }, [dispatch, lang])
  return (
      <FlexContainer>
        <Navigation lang={lang} formats={formats}/>
        <ContentContainer isActive={activeUrl}>
          <MobileHeader lang={lang}/>
          <CaseFrame />
          <ButtonContainer>
            <div>
              {isModalOpen && (
                <Modal
                  id="modal"
                  isOpen={isModalOpen}
                  onClose={toggleStateModal}
                >
                   <ContactCard setModalState={setModalState}/>
                </Modal>
              )}
            </div>
          </ButtonContainer>
        </ContentContainer>
      </FlexContainer>
  )
}