import { Dispatch } from "react"
import {ICaseModel} from "../models/format"

export const SET_ACTIVE_URL = 'ADD_ACTIVE_URL'
export const addActiveUrl = (url: string) => {
  return {
    type: SET_ACTIVE_URL,
    payload: url
  }
}
export const SET_ACTIVE_FORMAT = 'SET_ACTIVE_FORMAT'
export const setActiveFormat = (format: ICaseModel) => {
  return {
    type: SET_ACTIVE_FORMAT,
    payload: format
  }
}

export const FETCH_FORMATS = 'FETCH_FORMATS'
export const fetchFormats = (lang: string) => {
  return async (dispatch: Dispatch<any>) => {
    const response = await fetch(`${process.env.REACT_APP_REST_URI}/api/cases/?lang=${lang}`)
    const cases = await response.json()
    dispatch({type: FETCH_FORMATS, payload: cases})
  }
}
export const SEND_CONTACT = 'SEND_CONTACT'
export const sendContact = (data: any) => {
  return async (dispatch: Dispatch<any>) => {
    await fetch(`${process.env.REACT_APP_REST_URI}/api/contact/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
  }
}

export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
export const changeViewMode = (value: boolean) => {
  return {
    type: CHANGE_VIEW_MODE,
    payload: value
  }
}
export const MOBILE_VIEW_MODE = 'MOBILE_VIEW_MODE'
export const mobileViewMode = () => {
  return {
    type: MOBILE_VIEW_MODE,
    isMobileViewMode: true,
    isDesktopViewMode: false,
  }
}
export const DESKTOP_VIEW_MODE = 'DESKTOP_VIEW_MODE'
export const desktopViewMode = () => {
  return {
    type: DESKTOP_VIEW_MODE,
    isMobileViewMode: false,
    isDesktopViewMode: true,
  }
}