export const ru = {
  translation: {
    'Contact us': 'Связаться с нами',
    'Name': 'Имя',
    'Enter name': 'Введите имя',
    'E-mail': 'E-mail',
    'Enter e-mail': 'Введите e-mail',
    'Message': 'Сообщение',
    'Enter message': 'Введите сообщение',
    'Send': 'Отправить сообщение',
    "Scroll Page": "Начните скроллить",
  },
} as const