import styled from "styled-components"
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg'
import {device} from "../../styles/devices"

export const Icon = styled<any>(ArrowIcon)`
  transition: transform 0.3s ease;
  transform: ${({clicked}) => clicked? "rotate(0)": "rotate(-90deg)"};
  stroke: #FFFFFF;
`
export const SecondaryText = styled.div<any>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 115%;
  color: ${({clicked}) => clicked? "#FFFFFF": "#B3B3B3"};
`

const BaseButton = styled.button<any>`
  width: calc(100% - 16px);
  align-items: center;
  padding: 10px 8px;
  font-size: 14px;
  margin: 4px 8px;
  display: flex;
  border: none;
  border-radius: 4px;
  background: #FFFFFF;
  cursor: pointer;
  --color-primary-button: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #F29732;
  --color-secondary-button: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(91.7deg, #F04A5E 0%, #F8AF5A 50.73%, #F97A69 99.39%);
`

export const ButtonPrimary = styled(BaseButton)`  
  background: transparent;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;border-radius: 0;
  transition: all 0.3s ease;
  padding: 10px 0;
  color: white;
  box-shadow: ${({clicked, color}) => clicked? "none":  "0px 1px 0px rgba(255, 255, 255, 0.25)"};

  :hover {
    color: #FFFFFF;
  }
`
export const ButtonSecondary = styled(BaseButton)`
  background: ${({clicked}) => clicked ? "var(--color-secondary-button)": "transparent"};
  color:white;
  font-weight: normal;
  min-height: 40px;
  transition: background 0.3s ease;
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  
  :hover {
    ${({ clicked }) => !clicked? "background: #242424;": ""}
  }
`

export const ButtonContact = styled(BaseButton)`
  --button-margin: 45px;
  width: calc(100% - (2 * var(--button-margin)));
  margin: 16px;
  box-sizing: border-box;
  height: 50px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  background: white;
  border-radius: 52px;
  color: #000000;
  transition: all 0.3s ease;


  @media (${device.tablet}) {
    --button-margin: 35px;
  }

  :hover {
    border: 2px solid white;
    background: #060606;
    color: white;
  }
`

export const ContactFrameButton = styled(ButtonContact)`
    background: black;
    color: white;
    max-width: 300px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    
    :hover {
        border: none;
    }
    @keyframes slideOpen {
        from {
          transform: translate(0, 10px);
          opacity: 0;
        }
    }
    opacity: 100%;
    animation: .3s slideOpen;
`

export const SendButton = styled(ButtonContact)`
    background: black;
    color: white;
    width: 300px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    padding: 0 18px;

    :hover {
        border: 2px solid #000000;
        background: #ffffff;
        color: #000000;
    }
`

export const FlexBetweenLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const FlexCenterLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`