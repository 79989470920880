export const en = {
  translation: {
    'Contact us': 'Contact us',
    'Name': 'Name',
    'Enter your name': 'Enter your name',
    'E-mail': 'E-mail',
    'Enter your e-mail': 'Enter your e-mail',
    'Message': 'Message',
    'Enter your message': 'Enter your message',
    'Send': 'Send message',
    "Scroll Page": "Scroll Page",
  },
} as const