import {CHANGE_VIEW_MODE, DESKTOP_VIEW_MODE, FETCH_FORMATS, MOBILE_VIEW_MODE, SET_ACTIVE_URL, SET_ACTIVE_FORMAT, SEND_CONTACT} from "./actions"
import {initialState, IRootState} from "./initialState"


export const rootReducer = (state: IRootState = initialState, action: any) => {
  switch (action.type) {
    case SEND_CONTACT:
      return {...state}
    case SET_ACTIVE_FORMAT:
      return {...state, activeFormat: action.payload}
    case SET_ACTIVE_URL:
      return {...state, activeUrl: action.payload}
    case FETCH_FORMATS:
      return {...state, formats: action.payload}
    case CHANGE_VIEW_MODE:
      return {...state, isFullViewMode: action.payload}
    case MOBILE_VIEW_MODE:
      return {...state, isMobileViewMode: action.isMobileViewMode, isDesktopViewMode: action.isDesktopViewMode}
    case DESKTOP_VIEW_MODE:
      return {...state, isMobileViewMode: action.isMobileViewMode, isDesktopViewMode: action.isDesktopViewMode}
    default:
      return state
  }
}