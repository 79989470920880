import styled from "styled-components"
import {device} from "../../styles/devices"

export const CaseFrameStyle = styled.iframe`
  @media (${device.tablet}) {
    width: ${props => props.width};
    height: ${props => props.height};
    border: none;
  }
  width: 100%;
  height: 100%;
  border: none;
`

export const Layout = styled.div<any>`
  margin-top: 55px;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  height: calc(100% - 55px);
  display: ${({activeUrl}) => activeUrl? 'flex': 'none'};
  
  @media (${device.tablet}) {
    width: 100%;
    margin-top: 0;
    height: 100%;
    display: flex;
  }
`

export const MobilePlaceholder = styled.img`
  display: none;
  
  @media (${device.tablet}) {
    display: block;
    pointer-events: none;
    position: absolute;
    width: 394px; 
    height: 738px;
  }
  
`
