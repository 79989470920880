import {AppLogo, NavigationLayout, NavigationContainer, SecondButtonContainer, AppImage} from './style'
import logo from '../../logo.png'
import {ICaseModel, IFormatModel} from '../../models/format'
import {Resize} from "./style"
import { useTranslation} from "react-i18next"
import {useDispatch, useSelector} from "react-redux"
import {IRootState} from "../../store/initialState"
import Button from '../Button'
import React, {useCallback, useEffect, useState} from "react"
import Modal from "../Modal"
import {addActiveUrl, setActiveFormat} from "../../store/actions"
import {useMedia} from "react-use"
import {device} from "../../styles/devices"
import ContactCard from "../ContactCard"
import {useParams} from "react-router-dom"


interface NavigationsProps {
  lang: string
  formats: IFormatModel[]
}

interface PageUrlParams {
  pageSlug?: string
}



export default ({ formats, lang }: NavigationsProps) => {
  const { pageSlug } = useParams<PageUrlParams>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTablet = useMedia(`(${device.tablet})`)
  const { isFullViewMode, activeUrl } = useSelector((state: IRootState) => state)

  const [isModalOpen, setModalState] = useState(false)
  const [activeIdFormat, setActiveIdFormat] = useState<number>(-1)
  const [activeIdCase, setActiveIdCase] = useState<number>(-1)

  const setActiveUrlStore = useCallback((format: IFormatModel, _case: ICaseModel) => {
    setActiveIdFormat(format.id)
    setActiveIdCase(_case.id)
    dispatch(addActiveUrl(_case.url))
    dispatch(setActiveFormat(_case))
    window.history.replaceState(null, "", `/${lang}/${_case.slug}`)
  }, [dispatch, lang])

  const toggleStateModal = () => {
    setModalState(!isModalOpen)
  }

  useEffect(() => {
    if (isTablet) {
      const firstFormat = formats.length > 0? formats[0]: null
      const firstCase = formats.length > 0? formats[0].cases[0]: null

      if (firstFormat && firstCase) {
        setActiveUrlStore(firstFormat, firstCase)
      }
    }
  }, [formats, isTablet, dispatch, setActiveUrlStore])

  useEffect(() => {
    if (pageSlug) {
      formats.forEach(format => {
        format.cases.forEach(_case => {
          if (_case.slug === pageSlug) {
            setActiveUrlStore(format, _case)
          }
        })
      })
    }
  }, [formats, pageSlug, setActiveUrlStore])
  return (
    <NavigationContainer show={!isFullViewMode} isActiveUrl={activeUrl}>
      <div>
        <AppLogo isModile={isTablet}><div><a href="https://astravantage.com"><AppImage src={logo} alt="logo" /></a></div></AppLogo>
      </div>
        <NavigationLayout>
          {
            formats?.map((format) => {
              return <div key={format.id}>
                <Button
                  onClick={() => setActiveIdFormat(activeIdFormat === format.id? 0: format.id)}
                  clicked={activeIdFormat === format.id}
                  type="primary">{
                  format.name
                }
                </Button>
                <SecondButtonContainer
                  className={activeIdFormat === format.id? "animationIn": null}
                  visible={activeIdFormat === format.id} >{
                   format.cases.map((formatCase) => {
                    return <Button
                      onClick={() => {
                        setActiveUrlStore(format, formatCase)
                      }}
                      key={formatCase.id}
                      clicked={activeIdCase === formatCase.id}
                      type="secondary">
                      {formatCase}
                    </Button>
                  })}
                </SecondButtonContainer>
              </div>
            })
          }
        </NavigationLayout>
      <Resize>
          <Button
            type="contact"
            onClick={toggleStateModal} >{
              t("Contact us")
            }
          </Button>
      </Resize>
      <div>
        {isModalOpen && (
          <Modal
            id="modal"
            isOpen={isModalOpen}
            onClose={toggleStateModal}
          >
             <ContactCard setModalState={setModalState}/>
          </Modal>
        )}
      </div>
    </NavigationContainer>
  )
}
