import { NavIconsButtonStyle } from './style'

interface INavIconButtonProps {
  children: any
  onClick: () => void
  selected: boolean
  onMouseEnter?: any
  onMouseLeave?: any
  offHover?: boolean
}

export default ({ children, onMouseEnter, onMouseLeave, onClick, selected, offHover }: INavIconButtonProps) => {
  const currentOnClick = () => {
    onClick()
  }
  return <NavIconsButtonStyle offHover={offHover} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} selected={selected} onClick={currentOnClick}>{
    children
  }</NavIconsButtonStyle>

}