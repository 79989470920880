import React, { useEffect, useRef, useState} from "react"
import ReactDom from "react-dom"


// styled
import StyledModal, {CloseButton} from "./style"
import {ReactComponent as CloseIcon} from "../../assets/close.svg"

interface IModalProps {
  id: string
  onClose: () => void
  isOpen: boolean
  modalSize?:string
  children: any
}


const modalRoot = document.getElementById("root")

export default ({isOpen, onClose, id="", modalSize="md", children}: IModalProps) => {
  const [fadeType, setFadeType] = useState<string>()
  const background = React.createRef<any>()
  const mounted = useRef()

  const onEscKeyDown = (e: any) => {
    if (e.key !== "Escape") return
    setFadeType("out")
  }
  const transitionEnd = (e: any) => {
    if (e.propertyName !== "opacity" || fadeType === "in") return
    if (fadeType === "out") {
      onClose()
    }
  }
  const handleClick = (e: any) => {
    e.preventDefault()
    setFadeType("out")
  }


  useEffect(() => {
    if (!mounted.current) {
      window.addEventListener("keydown", onEscKeyDown, false)
      setTimeout(() => setFadeType("in"), 0)
    }
    else {
        setFadeType("out")
    }

    return () => {
      window.removeEventListener("keydown", onEscKeyDown, false)
    }
  }, [isOpen])

  return ReactDom.createPortal(
      <StyledModal
        id={id}
        className={`wrapper fade-${fadeType}`}
        role="dialog"
        onTransitionEnd={transitionEnd}
      >
        <div className="box-dialog">
          <div className="box-close">
            <CloseButton onClick={handleClick} >
              <CloseIcon stroke={"#B6B6B6"} />
            </CloseButton>
          </div>
          {children}
        </div>
        <div className={`background`} onMouseDown={handleClick} ref={background}/>
      </StyledModal>,
      modalRoot!
    )
}

