import {Container, Title, Text, Triangle, Info} from "./style"
import {useSelector} from "react-redux"
import {IRootState} from "../../store/initialState"

interface IInfoPopupProps {
  show: boolean
}

export default ({show}: IInfoPopupProps) => {
  const {
    activeFormat
  } = useSelector((state: IRootState) => state)
  return <Container show={show}>
    <Triangle />
    <Info show={show}>
      <Title>{activeFormat?.name}</Title>
      <Text>{activeFormat?.description}</Text>
    </Info>
  </Container>
}