import styled from 'styled-components'
import {device} from "../../styles/devices"
import { ReactComponent as MinIcon } from '../../assets/min.svg'


export const ContainerNavIconsStyle = styled.div`
  z-index: 10;
  position: absolute;
  background: #060606;;
  backdrop-filter: blur(20px);
  top: 125px;
  left: 15px;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  width: 50px;
  display: none;
  justify-content: space-between;
  flex-direction: column;
  
  @media (${device.tablet}) { 
     display: flex;
  }
  opacity: 50%;
  transition: opacity linear 0.15s;  
  :hover {
    opacity: 100%;
  }
`

export const IconStyle = styled.img`
  fill: ${props => props.color}
`

export const MinimazeIcon = styled<any>(MinIcon)`
  transition: all 0.3s ease;
  transform: ${({rotate}) => rotate? "rotate(180deg)": "rotate(0)"};
  padding: 1px 6px 1px 6px;
  
  :hover {
    stroke: white;
  }
`

export const Divider = styled.br`
   display: block;
  margin: 4px 0;
  height: 1px;
  width: 100%;
  line-height:22px;
  content: " ";
  background: rgba(255, 255, 255, 0.25);
`