import { CaseFrameStyle, Layout, MobilePlaceholder } from './style'
import NavIcons from "../NavIcons"
import InfoPopup from "../InfoPopup"
import { useSelector } from 'react-redux'
import {IRootState} from "../../store/initialState"
import {useEffect, useMemo, useState} from "react"
import device from '../../assets/device.png'

export default () => {
  const [size, setSize] = useState({width: '100%', height: '100vh'})
  const [showInfo, setShowInfo] = useState(false)

  const {
    activeUrl,
    isDesktopViewMode,
    isMobileViewMode,
    activeFormat
  } = useSelector((state: IRootState) => state)

  useEffect(() => {
    if (isMobileViewMode || activeFormat?.isMobile) {
      setSize({width: '350px', height: '700px'})
    }
    if (isDesktopViewMode && !activeFormat?.isMobile) {
      setSize({width: '100%', height: '100%'})
    }
  }, [activeFormat, isMobileViewMode, isDesktopViewMode])

  const finalUrl = useMemo(() => {
    const afpSettings = JSON.stringify({
     placeLogoType: "AstraVantage",
     bannerLang: "en"
    })
    return isMobileViewMode
          ? activeUrl + `?userAgentType=mobile&afpSettings=${afpSettings}`
          : activeUrl + `?afpSettings=${afpSettings}`
  }, [activeUrl, isMobileViewMode])
  console.log(activeUrl)
  return (
    <Layout activeUrl={!!activeUrl}>
      <NavIcons showInfo={setShowInfo}/>

      <InfoPopup show={showInfo}/>

      {isMobileViewMode &&
        <MobilePlaceholder
          alt={"mobile"}
          src={device}/>
      }
      {activeUrl &&
        <CaseFrameStyle
          width={size.width}
          height={size.height}
          title="case"
          src={finalUrl}
        />
      }
    </Layout>
  )
}