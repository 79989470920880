import {ICaseModel, IFormatModel} from "../models/format"

export interface IRootState {
  activeUrl: string,
  isFullViewMode: boolean,
  isMobileViewMode: boolean,
  isDesktopViewMode: boolean,
  formats: IFormatModel[]
  activeFormat?: ICaseModel
}

export const initialState: IRootState = {
  activeUrl: '',
  isFullViewMode: true,
  isMobileViewMode: false,
  isDesktopViewMode: true,
  formats: [],
  activeFormat: undefined
}
