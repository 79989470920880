import styled from "styled-components"
import {device} from "../../styles/devices"
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg'

export const HeaderContainer = styled.div<any>`
  align-items: center;
  position: fixed;
  color: white;
  display: flex;
  padding: 0 20px;
  justify-content: space-between;
  width: calc(100% - 40px);
  height: 55px;
  background: black;
  
  @media (${device.tablet}) { 
    display: none;
  }
  
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
`

export const Icon = styled<any>(ArrowIcon)`
  transform: rotate(90deg);
  stroke: #FFFFFF;
  width: 28px;
  height: 28px;
`