import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import Main from './pages/Main'

export default () => (
  <BrowserRouter>
    <Switch>

      <Route exact path="/ru/:pageSlug">
        <Main lang={"ru"}/>
      </Route>
      <Route exact path="/ru">
        <Main lang={"ru"}/>
      </Route>

      <Route exact path="/en/:pageSlug">
        <Main lang={"en"}/>
      </Route>
      <Route exact path="/en">
        <Main lang={"en"}/>
      </Route>

      <Route>
        <Redirect exact to='/ru'/>
      </Route>

    </Switch>
  </BrowserRouter>
)
