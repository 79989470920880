import {ReactComponent as DesktopIcon } from '../../assets/desktop.svg'
import { ReactComponent as MobileIcon } from '../../assets/mobile.svg'
import { ReactComponent as InfoIcon } from '../../assets/info.svg'
import { ContainerNavIconsStyle, MinimazeIcon, Divider} from './style'
import NavIconsButton from '../NavIconButton'
import {useDispatch, useSelector} from "react-redux"
import {changeViewMode, mobileViewMode, desktopViewMode} from "../../store/actions"
import {IRootState} from "../../store/initialState"
import {useEffect, useState} from "react"



interface INavIconsProps {
  showInfo: any
}

export default ({showInfo}: INavIconsProps) => {
  const dispatch = useDispatch()

  const [minColor, setMinColor] = useState("#B3B3B3")
  const [infoColor, setInfoColor] = useState("#B3B3B3")
  const [mobileColor, setMobileColor] = useState("#B3B3B3")
  const [desktopColor, setDesktopColor] = useState("#FFFFFF")

  const [isShowInfo, setShowInfo] = useState(false)
  const [isRotate, setRotate] = useState(false)
  const {
    isDesktopViewMode,
    isMobileViewMode,
    isFullViewMode,
    activeUrl,
    activeFormat
  } = useSelector((state: IRootState) => state)

  useEffect(() => {
    if (activeFormat?.isMobile) {
      setMobileColor("#ffffff")
      setDesktopColor("#B3B3B3")
      dispatch(mobileViewMode())
    } else {
      setDesktopColor("#ffffff")
      setMobileColor("#B3B3B3")
      dispatch(desktopViewMode())
    }
  }, [activeFormat?.isMobile])

  const onClickMobile = () => {
    setDesktopColor("#B3B3B3")
    dispatch(mobileViewMode())
  }
  const onClickDesktop = () => {
    setMobileColor("#B3B3B3")
    dispatch(desktopViewMode())
  }
  const hideOnClick = () => {
    setRotate(!isRotate)
    dispatch(changeViewMode(!isFullViewMode))
  }
  const onShowInfo = () => {
    showInfo(!isShowInfo)
    setShowInfo(!isShowInfo)
  }
  const funcChangeViewToMobile = activeUrl? onClickMobile: ()=>{}
  const funcViewInfo = activeUrl? onShowInfo: ()=>{}
  return (
    <ContainerNavIconsStyle >
      <NavIconsButton
        onMouseEnter={()=>setMinColor("#FFFFFF")}
        onMouseLeave={()=>setMinColor("#B3B3B3")}
        selected={false}
        onClick={hideOnClick}>
        <MinimazeIcon rotate={isRotate} stroke={minColor}/>
      </NavIconsButton>
      <Divider/>
      <NavIconsButton
        selected={false}
        onClick={()=>{}}
        onMouseEnter={()=> {
          funcViewInfo()
          setInfoColor(isShowInfo? "#FFFFFF": "#FFFFFF")
        }}
        onMouseLeave={()=> {
          funcViewInfo()
          setInfoColor("#B3B3B3")
        }}>
        <InfoIcon
          stroke={infoColor}
        />
      </NavIconsButton>
      <Divider/>
      <NavIconsButton
         onMouseEnter={()=> {
          setMobileColor(isMobileViewMode? "#FFFFFF": "#FFFFFF")
        }}
        onMouseLeave={()=> {
          setMobileColor(isMobileViewMode? "#FFFFFF": "#B3B3B3")
        }}
        selected={isMobileViewMode}
        onClick={funcChangeViewToMobile}>
        <MobileIcon
          stroke={mobileColor}
        />
      </NavIconsButton>
      <Divider/>
      <NavIconsButton
         onMouseEnter={()=> {
           if (!activeFormat?.isMobile) {
             setDesktopColor(isDesktopViewMode? "#FFFFFF": "#FFFFFF")
           }
        }}
        onMouseLeave={()=> {
           if (!activeFormat?.isMobile) {
              setDesktopColor(isDesktopViewMode? "#FFFFFF": "#B3B3B3")
           }
        }}
        selected={isDesktopViewMode}
        offHover={activeFormat?.isMobile}
        onClick={!activeFormat?.isMobile ? onClickDesktop : () => {}}>
        <DesktopIcon
          stroke={desktopColor}
        />
      </NavIconsButton>
    </ContainerNavIconsStyle>
  )
}