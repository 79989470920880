import styled from "styled-components"
import {device} from "../../styles/devices"

export const FlexContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
`
export const ContentContainer = styled.div<any>`
  width: 100%;
  display: ${({isActive}) => isActive? "block": "none"};

  @media (${device.tablet}) {
    width: 100%;
    display: flex;
  }
`
export const ButtonContainer = styled.div<any>`
 position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  
  @media (${device.tablet}) { 
    display: none;
  }
`