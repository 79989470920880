import {HeaderContainer, Icon, Title} from "./style"
import {useDispatch, useSelector} from "react-redux"
import logo from '../../assets/mobileLogo.svg'
import {addActiveUrl} from "../../store/actions"
import React from "react"
import {IRootState} from "../../store/initialState"


interface MobileHeaderProps {
  lang: string
}

export default ({lang}: MobileHeaderProps) => {
  const dispatch = useDispatch()
  const {
    activeFormat
  } = useSelector((state: IRootState) => state)
  const onClick = () => {
    dispatch(addActiveUrl(""))
    window.history.replaceState(null, "", `/${lang}`)
  }
  return (
    <HeaderContainer>
      <Icon onClick={onClick}/>
      <Title>{activeFormat?.name}</Title>
      <div/>
    </HeaderContainer>
  )

}