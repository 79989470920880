import styled from "styled-components"
import {device} from "../../styles/devices"

export const NavigationContainer = styled.div<any>`
  background-color: #060606;
  padding: 0 8px 0 8px;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ isActiveUrl }) => isActiveUrl? null: "100%"};
  display: ${({ isActiveUrl }) => isActiveUrl? "none": "flex"};

  @media (${device.tablet}) { 
    width: 290px;
    display: ${({ show }) => show? "none": "flex"};
  }
`

export const AppLogo = styled.div<any>`
  height: 110px;
  display: flex;
  padding: 0 17px;
  justify-content: start;
  align-items: center;
  
  @media (${device.tablet}) { 
    justify-content: center;
  }
`
export const AppImage = styled.img<any>`
  width: 110px;
`

export const NavigationLayout = styled.div`
  //FIXME: fix this
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  
  ::-webkit-scrollbar {
    width: 0;
  }
  
  @media (${device.tablet}) { 
    width: 290px;
      
    ::-webkit-scrollbar {
      width: 5px;
    }
  }

`
export const Resize = styled.div<any>`
  width: ${({size}) => size};
  display: flex;
  justify-content: center;
`

export const FlexLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const Container = styled.div<any>`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 40px;
  
  @media (${device.tablet}) {
     width: 550px;
    height: 661px;
    position: absolute;
    box-sizing: border-box;
    padding: 40px;
    border-radius: 15px;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 110%;
  text-align: center;
  margin: 0 0 40px 0;
  color: #060606;
`
export const Article = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  margin: 24px 0 8px 0;
  line-height: 130%;
  color: #B3B3B3;
`
export const Input = styled.input<any>`
  padding-left: 12px;
  border-color: ${({colorBorder}) => colorBorder};
  box-sizing: border-box;
  font-size: 17px;
  line-height: 140%;
  width: 100%;
  height: 48px;
  background: #ECECEC;
  border-style: solid;
  border-radius: 4px;
`
export const TextAria = styled.textarea<any>`
  resize: none;
  height: 130px;
  padding: 12px;
  box-sizing: border-box;
  font-size: 17px;
  line-height: 140%;
  width: 100%;
  background: #ECECEC;
  border-color: ${({colorBorder}) => colorBorder};
  border-radius: 4px;
  border-width:  2px;
  
  ::-webkit-scrollbar-thumb {
    background-color: #8e8e8e;
    border-radius: 10px;
    border: 1px solid transparent;
    background-clip: content-box;
  }
`

export const CloseButton = styled.button`
  position: relative;
  top: -16px;
  right: -16px;
  padding: 0;
  border: none;
  background-color: inherit;
  cursor: pointer;
`

export const Center = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
`

export const SecondButtonContainer = styled.div<any>`
  overflow: hidden;
  
  &.animationIn {
    display: block;
    
    @keyframes slideIn {
      from {
        height: 0;
      }
      to {
        height: 100%;
      }
    }
    overflow: hidden;
    animation: slideIn 0.2s ease;
  } 
  
  &.animationOut {
    display: block;
    
    @keyframes slideOut {
      to {
        height: 0;
      }
      from {
        height: 100%;
      }
    }
    overflow: hidden;
    animation: slideOut 1s ease-in-out;
  }
   display: ${ ({visible}) => visible? "block": "none" };
  height: ${ ({visible}) => visible? "auto": "0" };
`
